import { type ReactNode,
  useMemo } from 'react';
import React, { createContext,
  useContext,
  useState } from 'react';

type BlockType = 'change-password' | 'change-phone' | 'change-verification' | 'edit-fields' | null;

type BlockDisplayContextType = {
  activeBlock: BlockType,
  setActiveBlock: (block: BlockType) => void,
};

const BlockDisplayContext = createContext<BlockDisplayContextType | undefined>(undefined);

export const useBlockDisplay = () => {
  const context = useContext(BlockDisplayContext);
  if (!context) {
    throw new Error('useBlockDisplay must be used within a BlockDisplayProvider');
  }

  return context;
};

export const BlockDisplayProvider = ({ children }: { children: ReactNode, }) => {
  const [
    activeBlock,
    setActiveBlock,
  ] = useState<BlockType>('edit-fields');

  const value = useMemo(() => ({ activeBlock,
    setActiveBlock }), [
    activeBlock,
    setActiveBlock,
  ]);

  return (
    <BlockDisplayContext.Provider value={value}>
      {children}
    </BlockDisplayContext.Provider>
  );
};
