import './SettingsModule.scss';
import ProfileSvg from '../../assets/icons/global/profile.svg?react';
import MenuCloseSvg from '../../assets/icons/sidebar/menu-close.svg?react';
import { ChangePasswordBlock } from './ChangePasswordBlock/ChangePasswordBlock';
import { ChangePhoneNumberBlock } from './ChangePhoneNumberBlock/ChangePhoneNumberBlock';
import { ChangeVerificationBlock } from './ChangeVerificationBlock/ChangeVerificationBlock';
import { useBlockDisplay } from './contexts/BlockDisplayContext.context';
import { ChangePasswordContextProvider } from './contexts/ChangePassword.context';
import { ChangePhoneNumberContextProvider } from './contexts/ChangePhoneNumber.context';
import { EditableField } from './EditableField/EditableField';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { ModalActivationContext } from 'contexts';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'services/mobile';
import { type VerificationChannel } from 'types';
import { CHANNEL_MAP } from 'types';

export const SettingsModule = () => {
  const { toggleSettingsModal } = useContext(ModalActivationContext);
  const { activeBlock,
    setActiveBlock } = useBlockDisplay();

  const email = useSelector<
  { auth: { email: string, }, }>(
    (state) => state.auth.email,
  ) as string;
  const phone = useSelector<
  { auth: { phone: string, }, }>(
    (state) => state.auth.phone,
  ) as number;

  const verificationChannel = useSelector<
  { auth: { verificationChannel: VerificationChannel, }, }>(
    (state) => state.auth.verificationChannel,
  ) as VerificationChannel;

  const mobileContainer = () => <Modal data-test='performance-reports-modal-container'>
    <div className='settings-container mb-[1rem] flex items-center dt:mb-[1.25rem]'>
      <div className='flex items-center  gap-[0.81rem]'>
        {activeBlock === 'edit-fields' ? <div className='profile relative z-20 flex size-2 cursor-pointer items-center justify-center overflow-hidden rounded-large bg-gradient-to-b from-[#5E5F5F] to-[#8F8F90] p-0'><ProfileSvg /></div>
          : <div onClick={() => setActiveBlock('edit-fields')}><Svgicon className='size-1' id='UP-arrow-left' /></div>}
        <p className='m-0'>{email}</p>
      </div>
      <div onClick={() => toggleSettingsModal(false)}><MenuCloseSvg /></div>
    </div>
    <div className='h-full px-[0.75rem] pb-8'>
      {activeBlock === 'edit-fields' &&
      <div className='flex flex-col items-start gap-[1rem] self-stretch'>
        <EditableField buttonName={`${phone ? 'Edit' : 'Add'}`} dataTest={`${phone ? 'edit' : 'add'}-phone-button`} onEdit={() => setActiveBlock('change-phone')} title='Phone' value={`${phone ? `+${phone}` : 'No phone number'}`} />
        <EditableField buttonName='Edit' dataTest='edit-password-button' onEdit={() => setActiveBlock('change-password')} title='Password' value='••••••••' />
        <EditableField buttonName='Edit' dataTest='edit-verification-button' onEdit={() => setActiveBlock('change-verification')} title='Verification Method' value={CHANNEL_MAP.get(verificationChannel)?.display} />
      </div>
      }
      {activeBlock === 'change-password' && <ChangePasswordContextProvider><ChangePasswordBlock /></ChangePasswordContextProvider>}
      {activeBlock === 'change-phone' &&
        <ChangePhoneNumberContextProvider>
          <ChangePhoneNumberBlock shouldChangeVerificationMethod={false} />
        </ChangePhoneNumberContextProvider>}
      {activeBlock === 'change-verification' && <ChangeVerificationBlock />}
    </div>
  </Modal>;

  if (isMobile()) {
    return mobileContainer();
  }

  return <Modal data-test='performance-reports-modal-container'>
    <div className='main-white-card relative h-full  !w-[30.875rem] rounded-none px-7 pb-6 pt-[1rem] dt:size-auto dt:rounded-medium dt:px-[0.875rem]'>
      <span
        className='absolute right-[1.25rem] top-[0.375rem] z-[1010] size-1.5 cursor-pointer dt:right-[0.375rem]'
        data-test='performance-report-modal-close-button'
        onClick={() => toggleSettingsModal(false)}
      >
        <Svgicon className='rounded-[50%] bg-main-700 text-white-999' id='close-1f' />
      </span>
      <div className='scrollContainer  flex h-full flex-col'>
        <div className='mb-[1.25rem] flex items-center gap-[0.81rem]'>
          <div className='profile relative z-20 flex size-2 cursor-pointer items-center justify-center overflow-hidden rounded-large bg-gradient-to-b from-[#5E5F5F] to-[#8F8F90] p-0'><ProfileSvg /></div>
          <p className='m-0'>{email}</p>
        </div>
        {activeBlock === 'edit-fields' &&
        <div className='flex flex-col items-start gap-[1rem] self-stretch'>
          <EditableField buttonName={`${phone ? 'Edit' : 'Add'}`} dataTest={`${phone ? 'edit' : 'add'}-phone-button`} onEdit={() => setActiveBlock('change-phone')} title='Phone' value={`${phone ? `+${phone}` : 'No phone number'}`} />
          <EditableField buttonName='Edit' dataTest='edit-password-button' onEdit={() => setActiveBlock('change-password')} title='Password' value='••••••••' />
          <EditableField buttonName='Edit' dataTest='edit-verification-button' onEdit={() => setActiveBlock('change-verification')} title='Verification Method' value={CHANNEL_MAP.get(verificationChannel)?.display} />
        </div>
        }
        {activeBlock === 'change-password' && <ChangePasswordContextProvider><ChangePasswordBlock /></ChangePasswordContextProvider>}
        {activeBlock === 'change-phone' &&
        <ChangePhoneNumberContextProvider>
          <ChangePhoneNumberBlock shouldChangeVerificationMethod={false} />
        </ChangePhoneNumberContextProvider>}
        {activeBlock === 'change-verification' && <ChangeVerificationBlock />}
        <div />
      </div>
    </div>
  </Modal>;
};
