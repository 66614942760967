import { type DocumentCategoryDTO,
  type DocumentDTO,
  type IReportGroup } from '../types';
import { Api } from './Api';
import { Alert,
  unauthorized } from './utils';
import { isAxiosError } from 'axios';
import environment from 'config/environment';
import { notificationsService } from 'services';

const documentsApiRequester = new Api('/api/v2/documents');
const reportsApiRequester = new Api('/api/v2/reports/v2');
const filesApiRequester = new Api('/api/v2/files');

export type DocumentPeriodsFilter = {
  accountIds: string[],
  displayType: string,
  firmId: string,
  fundIds: string[],
  quarters?: string[],
  years?: string[],
};

const fetchDocument = async (docId: string) => {
  try {
    const resp = await filesApiRequester.get<Blob>(
      `/${docId}`,
      'blob',
      'application/pdf',
    );

    const blob = new Blob([
      resp.data,
    ], { type: 'application/pdf' });
    return { ...resp,
      data: blob };
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve document');
    }

    throw new Error('Could not retrieve document.');
  }
};

const downloadDocument = async (name: string, docId: string) => {
  const response = await fetchDocument(docId as string);
  const downloadUrl = window.URL.createObjectURL(response.data);
  const downloadLink = document.createElement('a');
  downloadLink.href = downloadUrl;
  downloadLink.download = name.endsWith('.pdf') ? name : `${name}.pdf`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  window.URL.revokeObjectURL(downloadUrl);
};

const downloadDocumentMobile = async (fileName: string, docId: string) => {
  try {
    const url = `${environment.apiUrl}/api/v2/files/${docId}`;

    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    notificationsService.error(`Error while downloading the file: ${error}`);
  }
};

const getDocumentCategories = async (filter: DocumentPeriodsFilter) => {
  try {
    const {data} = await documentsApiRequester.post<
    DocumentPeriodsFilter, DocumentCategoryDTO[]>(
      '/categories',
      filter,
      {},
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve categories');
    }

    return [];
  }
};

export type DocumentsFilter = DocumentPeriodsFilter & {
  filterByLatestDocuments?: boolean,
  filterByNewDocuments: boolean,
};
const getDocuments = async (
  categoryId: string,
  fileType: string,
  filter: DocumentsFilter,
) => {
  try {
    const {data} = await documentsApiRequester
      .post<DocumentsFilter, DocumentDTO[]>(
      `/categories/${categoryId}/file-types/${fileType}`,
      filter,
      {},
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve documents');
    }

    throw new Error('Could not retrieve documents.');
  }
};

const getLatestDocuments = async (
  filter: DocumentsFilter,
  page: number,
  size: number,
) => {
  try {
    const {data} = await documentsApiRequester
      .post<DocumentsFilter, DocumentDTO[]>(
      '/categories/file-types/all',
      filter,
      {
        params: { page,
          size },
      },
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve documents');
    }

    throw new Error('Could not retrieve documents.');
  }
};

const viewDocuments = async (
  documentIds: object,
) => {
  try {
    const {data} = await documentsApiRequester
      .post<object, unknown>(
      '/view',
      documentIds,
      {},
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not view documents');
    }

    throw new Error('Could not view documents.');
  }
};

type ReportFilter = {
  reportId: string,
};
const getReportToken = async ({ reportId }: ReportFilter) => {
  const url = `/${reportId}`;

  try {
    const {data} = await reportsApiRequester.get<{
      reportTokenObject: string,
    }>(
      url,
    );

    return data.reportTokenObject;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve report');
    }

    throw new Error('Could not retrieve report.');
  }
};

type AllReportsFilter = {
  firmId: string,
  fundIds: string[],
};
const getReports = async (filter: AllReportsFilter) => {
  try {
    const {data} = await reportsApiRequester.post<
    AllReportsFilter, IReportGroup[]>(
      '/all',
      filter,
      {},
    );

    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve reports');
    }

    throw new Error('Could not retrieve reports.');
  }
};

export const documentsApi = {
  downloadDocument,
  downloadDocumentMobile,
  fetchDocument,
  getDocumentCategories,
  getDocuments,
  getLatestDocuments,
  getReports,
  getReportToken,
  viewDocuments,
};

