import style from './LoginInput.module.scss';
import { useEffect,
  useRef } from 'react';

export enum InputIcon {
  Eye = 'eye',
  OpenedEye = 'opened-eye'
}

const EyeIcon = () => <svg fill='none' height='11' viewBox='0 0 18 11' width='18' xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M7.00634 10.0865L5.55809 9.69802L6.14834 7.49377C5.26481 7.16792 4.44367 6.69304 3.72059 6.08977L2.10584 7.70527L1.04459 6.64402L2.66009 5.02927C1.74814 3.93709 1.1354 2.62684 0.881836 1.22677L2.35784 0.95752C2.92709 4.10902 5.68409 6.50002 8.99984 6.50002C12.3148 6.50002 15.0726 4.10902 15.6418 0.95752L17.1178 1.22602C16.8646 2.62628 16.2521 3.9368 15.3403 5.02927L16.9551 6.64402L15.8938 7.70527L14.2791 6.08977C13.556 6.69304 12.7349 7.16792 11.8513 7.49377L12.4416 9.69877L10.9933 10.0865L10.4023 7.88152C9.4741 8.04058 8.52557 8.04058 7.59734 7.88152L7.00634 10.0865Z'
    fill='#263238'
    fillOpacity='0.64'
  />
</svg>;

const OpenedEyeIcon = () => <svg fill='none' height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg'>
  <path clipRule='evenodd' d='M6.43994 9.02998C6.43994 10.47 7.62994 11.64 9.09994 11.64C10.5699 11.64 11.7599 10.47 11.7599 9.02998C11.7599 7.58998 10.5699 6.41998 9.09994 6.41998C7.62994 6.41998 6.43994 7.58998 6.43994 9.02998ZM8.15994 9.02998C8.15994 8.53998 8.57994 8.13998 9.09994 8.13998C9.61994 8.13998 10.0399 8.53998 10.0399 9.02998C10.0399 9.51998 9.61994 9.91998 9.09994 9.91998C8.57994 9.91998 8.15994 9.51998 8.15994 9.02998Z' fill='#263238' fillOpacity='0.64' fillRule='evenodd' />
  <path clipRule='evenodd' d='M9.09 15.01H9.24L9.23 14.99C13.98 14.99 16.46 11.32 17.11 9.34999L17.2 9.08999L17.12 8.82999L17.1196 8.82863C17.0808 8.71134 15.1889 2.98999 9.1 2.98999C3.01111 2.98999 1.11922 8.71138 1.08045 8.82863L1.08 8.82999L1 9.09999L1.09 9.36999C1.11 9.42999 3.07 14.91 9.09 15.01ZM9.25 13.29H9.12V13.28C4.96 13.22 3.25 10.04 2.83 9.07999C3.23 8.08999 4.89 4.72999 9.11 4.72999C13.33 4.72999 14.99 8.09999 15.39 9.08999C14.98 10.06 13.31 13.29 9.25 13.29Z' fill='#263238' fillOpacity='0.64' fillRule='evenodd' />
</svg>;

const icons = {
  [InputIcon.Eye]: EyeIcon,
  [InputIcon.OpenedEye]: OpenedEyeIcon,
};

type Props = {
  autoCompleteField?: string,
  className?: string,
  icon?: InputIcon,
  // for e2e tests, so element can be find easily
  name?: string,
  onChange?: (string_: string) => void,
  onIconClick?: () => void,
  placeholder?: string,
  shouldFocus?: boolean,
  type?: 'email' | 'number' | 'password' | 'text',
  value?: string,
};

const getIcon = (icon: InputIcon, onClick: () => void) => {
  const IconComponent = icons[icon];

  return (
    <span
      className={style.loginIcon} onClick={onClick}
    >
      <IconComponent />
    </span>
  );
};

export const LoginInput = ({
  type = 'text',
  value = '',
  placeholder = '',
  onChange = () => {},
  icon,
  onIconClick = () => {},
  className,
  name = '',
  shouldFocus = false,
  autoCompleteField,
}: Props) => {
  const id = `login-input-${Math.random()}`;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [
    shouldFocus,
  ]);

  return (
    <div className={`${style.loginInput} ${className}`}>
      <input
        autoComplete={autoCompleteField}
        data-autofill='disabled'
        data-test={name}
        id={id}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        ref={inputRef}
        type={type}
        value={value}
      />
      {icon ? getIcon(icon, onIconClick) : null}
    </div>
  );
};
