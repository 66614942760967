import { Button } from 'components/InteractiveUIControls/Button/Button';
import { type FC } from 'react';
import { isMobile } from 'services/mobile';

type ButtonAreaProps = {
  buttonText: string,
  disabled?: boolean,
  isLoading?: boolean,
  onCancel: (event?: React.MouseEvent<HTMLElement>) => void,
  onSave: (event?: React.MouseEvent<HTMLElement>) => Promise<void> | void,
};

export const ButtonArea: FC<ButtonAreaProps> = ({onCancel,
  onSave,
  disabled,
  isLoading,
  buttonText}) => {
  const mobile = isMobile();
  return <div className='flex w-full justify-end gap-[0.5rem]'>
    {!mobile && <Button
      className='!h-8 !rounded-[2rem] !px-6 !py-[0.31rem] !text-font-2 !font-normal'
      data-test='cancel-button'
      onClick={(event) => onCancel(event)}
      size='medium'
      squared
      tooltipWrapperClass='!w-auto'
      type='white-main'
    >
      Back
    </Button>}
    <Button
      className='w-full !px-6 !py-[0.31rem] !text-font-2 !font-normal dt:!h-8 dt:w-auto dt:!rounded-[2rem]'
      data-test='save-button'
      disabled={disabled}
      isLoading={isLoading}
      onClick={(event) => onSave(event)}
      size={mobile ? 'large' : 'medium'}
      squared
      tooltipWrapperClass='dt:w-auto'
      type='main'
    >
      {buttonText}
    </Button>
  </div>;
};
