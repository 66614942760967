import './DocumentGroupName.scss';
import { documentsApi } from 'api/documentsApi';
import { actionCreator } from 'api/utils';
import ClosedFolder from 'assets/icons/documents/closed-folder.svg?react';
import OpenedFolder from 'assets/icons/documents/opened-folder.svg?react';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Tooltip } from 'components/InteractiveUIControls/Tooltip/Tooltip';
import { limitString } from 'components/Pipes/textPipes';
import { ModalActivationContext } from 'contexts';
import { type FilesStore,
  type IndividualFileStore } from 'pages/Firm/FileViewModal/FileViewModal';
import {
  type FC,
  useContext,
} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { isMobile } from 'services/mobile';
import { filesActionTypes } from 'store/actions';
import { type DocumentsStoreState,
  type Filters,
  type FilterStoreState,
  type PeriodFilters } from 'store/slices';
import { markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDTO,
  getItemsFromStorage,
} from 'types';

type DocumentGroupNameProps = {
  categoryId: string,
  documentGroupId: string,
  documentGroupName: string,
  documents: DocumentDTO[],
  isOpen: boolean,
  numberOfDocuments: number,
  numberOfNewDocuments: number,
};

export const DocumentGroupName: FC<DocumentGroupNameProps> = ({
  documentGroupName,
  documentGroupId,
  documents,
  categoryId,
  isOpen,
  numberOfDocuments,
  numberOfNewDocuments,
}) => {
  const dispatch = useDispatch();
  const { toggleDownloadsModal } = useContext(ModalActivationContext);
  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;
  const {
    viewingFileByIdLoading,
  } = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;
  const {
    displayType,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;
  const periodFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.usualDocumentsFilter) as PeriodFilters | null;

  const { loginAs } = getItemsFromStorage([
    'loginAs',
  ], sessionStorage);
  const getUnseenDocuments = async () => {
    if (!isOpen && !documents && activeFilters) {
      const fetchedDocuments = await documentsApi.getDocuments(
        categoryId as string,
        documentGroupId as string,
        {
          filterByNewDocuments: false,
          firmId: activeFilters.organizationId,
          ...activeFilters,
          displayType: displayType.id,
          ...periodFilters,
        },
      );
      return fetchedDocuments.filter((doc) => !doc.userViewed);
    } else {
      return documents.filter((doc) => !doc.userViewed);
    }
  };

  const markAsSeenClick = async (
    event?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event?.stopPropagation();

    dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, true));

    const filteredNewDocs = await getUnseenDocuments();

    if (filteredNewDocs.length > 0) {
      const documentIds = filteredNewDocs.map((doc) => doc._id);
      await documentsApi.viewDocuments({ documentIds });
      dispatch(markDocumentsAsSeen({ categoryId,
        documentGroupId,
        documentIds }));
    }

    dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
  };

  const onClickDownload = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    toggleDownloadsModal(true, documentGroupId);
  };

  return (
    <div
      className='nameContainer'
      style={isOpen ? {
        borderBottom: '2px solid rgba(221, 222, 223, 0.3)',
        borderRadius: '0.5rem 0.5rem 0 0',
      } : {}}
    >
      <div className='nameLeft'>
        {
          isOpen ?
            <OpenedFolder /> :
            <ClosedFolder />
        }
        <div className='documentGroupDetails'>
          <h3 className='documentGroupName'>
            {isMobile() ? limitString(documentGroupName, 24) : documentGroupName}
          </h3>
          <h3 className='documentTotalDocumentNumber'>
            {`${numberOfDocuments} documents`}
          </h3>
        </div>
      </div>
      {
        <div className='nameRight'>
          {Boolean(numberOfNewDocuments) &&
            numberOfNewDocuments > 0 && !loginAs && !isMobile() &&
            <Button
              className='fileViewButton'
              data-test='mark-file-as-seen'
              icon='left'
              iconid='mark-seen'
              isLoading={viewingFileByIdLoading}
              onClick={(event) => markAsSeenClick(event)}
              size='medium'
              tooltipWrapperClass=' min-w-fit'
              type='grey'
            >Mark as seen </Button>}
          {(!isMobile() || isOpen) && displayType.id === 'type' && <Button
            className='fileViewButton'
            data-test='download-file'
            icon='left'
            iconid='download-cloud'
            onClick={(event) => onClickDownload(event)}
            size='medium'
          >
            Download
          </Button>}
          {Boolean(numberOfNewDocuments) &&
            numberOfNewDocuments > 0 &&
            <div className='tooltip-wrapper black number-of-new-docs w-fit'>
              <Tooltip>{`${numberOfNewDocuments} new document${numberOfNewDocuments === 1 ? '' : 's'}`}</Tooltip>
              <div className='numDocumentsContainer'>
                <p className='numberOfDocuments'>
                  {numberOfNewDocuments}
                </p>
              </div>
            </div>}

        </div>
      }
    </div>
  );
};
