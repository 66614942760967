/* eslint-disable canonical/filename-match-regex */
/* eslint-disable canonical/filename-match-exported */
import Api from '.';
import { type Account } from 'types';

class UsersApi extends Api {
  private apiVersion: string;

  public constructor (version: string = '') {
    super(`/api${version ? `/${version}` : ''}/users`);
    this.apiVersion = version;
  }

  public async getAccounts (fundIds: string[]) {
    if (!fundIds?.length) {
      return [];
    }

    const { data } = await this.post('/funds/accounts', {fundIds});

    return data as Account[];
  }

  public async changeDisplayType (displayType: string) {
    const path = '/change-display-type';
    const { data } = await this.post(path, {displayType});
    return data;
  }
}

const userApiV1 = new UsersApi();
const userApiV2 = new UsersApi('v2');
export { userApiV1,
  userApiV2 };

