import './TreeMultiSelector.scss';
import { TreeMultiSelectorOption } from './TreeMultiSelectorOption/TreeMultiSelectorOption';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { arraysEqual } from 'helpers/helpers';
import {
  type FC,
  type SetStateAction,
  useEffect,
  useRef,
} from 'react';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { isMobile } from 'services/mobile';

export type TreeNode = {
  children?: TreeNode[],
  id: string,
  name: string,
};

type TreeMultiSelectorProps = {
  dataSelectionValue: string,
  disabled: boolean,
  inputStyle: string,
  isResettable?: boolean,
  onCheck: (parents: string[], childrenNodes: string[]) => void,
  placeholder: string,
  selectedData: TreeNode[],
  treeData: TreeNode[],
};

export const getAllIds =
  (nodes: TreeNode[]): { childrenNodes: Set<string>, parents: Set<string>, } => {
    const parents = new Set<string>();
    const childrenNodes = new Set<string>();

    const traverse = (node: TreeNode, isParent: boolean) => {
      if (isParent) {
        parents.add(node.id);
      } else {
        childrenNodes.add(node.id);
      }

      if (node.children) {
        for (const child of node.children) {
          traverse(child, false);
        }
      }
    };

    for (const node of nodes) {
      traverse(node, true);
    }

    return {
      childrenNodes,
      parents,
    };
  };

const CustomSuffix =
  (isResettable: boolean, handleReset: () => void, allSelected: boolean) =>
    <>
      {!allSelected && isResettable && <span className='size-[1rem] cursor-pointer' onClick={handleReset}>
        <Svgicon className='z-50 size-[1rem] cursor-pointer' id='close-1f' />
      </span>}
    </>;

const TreeMultiSelector: FC<TreeMultiSelectorProps> = ({ treeData,
  inputStyle,
  placeholder,
  disabled,
  dataSelectionValue,
  onCheck,
  selectedData,
  isResettable }) => {
  const { parents: initialSelectedParents,
    childrenNodes: initialSelectedChildren } = getAllIds(selectedData);

  const mobile = isMobile();

  const [
    selectedParent,
    setSelectedParent,
  ] = useState<Set<string>>(initialSelectedParents);

  const [
    selectedChildren,
    setSelectedChildren,
  ] = useState<Set<string>>(initialSelectedChildren);

  const [
    focused,
    setFocused,
  ] = useState(false);

  useEffect(() => {
    setSelectedParent(getAllIds(selectedData).parents);
    setSelectedChildren(getAllIds(selectedData).childrenNodes);
  }, [
    selectedData,
  ]);

  const componentRef = useRef(
    null,
  ) as unknown as React.MutableRefObject<HTMLDivElement>;

  const updateSelections =
    (newSelectedParents: Set<string>, newSelectedChildren: Set<string>) => {
      setSelectedParent(newSelectedParents);
      setSelectedChildren(newSelectedChildren);

      if (!mobile) {
        onCheck([
          ...newSelectedParents,
        ], [
          ...newSelectedChildren,
        ]);
      }
    };

  const handleReset = () => {
    updateSelections(getAllIds(treeData).parents,
      getAllIds(treeData).childrenNodes);
  };

  const toggleChildrenSelection =
    (children: TreeNode, event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const newSelectedChildren = new Set(selectedChildren);
      if (newSelectedChildren.has(children.id)) {
        newSelectedChildren.delete(children.id);
      } else {
        newSelectedChildren.add(children.id);
      }

      const parentNode = treeData.find((node) =>
        node.children?.some((child) => child.id === children.id),
      );

      const newSelectedParents = new Set(selectedParent);
      if (parentNode) {
        const childrenNodes = parentNode.children?.map((child) => child.id) || [];
        const allChildrenSelected = childrenNodes
          .every((id) => newSelectedChildren.has(id));

        if (allChildrenSelected) {
          newSelectedParents.add(parentNode.id);
        } else {
          newSelectedParents.delete(parentNode.id);
        }
      }

      updateSelections(newSelectedParents, newSelectedChildren);
    };

  const toggleParentSelection =
    (parentNode: TreeNode, event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const newSelectedParents = new Set(selectedParent);
      const newSelectedChildren = new Set(selectedChildren);
      const childrenNodes = parentNode.children?.map((child) => child.id) || [];

      if (childrenNodes.every((child) => newSelectedChildren.has(child))) {
        for (const child of childrenNodes) {
          newSelectedChildren.delete(child);
        }

        newSelectedParents.delete(parentNode.id);
      } else {
        for (const child of childrenNodes) {
          newSelectedChildren.add(child);
        }

        newSelectedParents.add(parentNode.id);
      }

      updateSelections(newSelectedParents, newSelectedChildren);
    };

  const applyPeriods = () => {
    if (arraysEqual(Array.from(selectedChildren),
      Array.from(getAllIds(selectedData).childrenNodes))) {
      setFocused(false);
      return;
    }

    onCheck([
      ...selectedParent,
    ], [
      ...selectedChildren,
    ]);

    setFocused(false);
  };

  const isParentSelected = (parentNode: TreeNode) => {
    const childrenNodes = parentNode.children?.map((child) => child.id) || [];
    return childrenNodes.every((child) => selectedChildren.has(child));
  };

  const isSomeUnderParentSelected = (parentNode: TreeNode) => {
    const childrenNodes = parentNode.children?.map((child) => child.id) || [];
    return childrenNodes.some((child) => selectedChildren.has(child));
  };

  const toggleOpen = (
    open: SetStateAction<boolean>,
    event: MouseEvent | React.MouseEvent<HTMLDivElement | HTMLSpanElement>,
  ) => {
    event.stopPropagation();
    if (open === focused) {
      return;
    }

    setFocused(open);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => {
    if (
      focused &&
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [
    handleClickOutside,
  ]);

  const isChildrenSelected = (childId: string) => selectedChildren.has(childId);

  const allSelected = Array.from(selectedChildren.entries()).length ===
    Array.from(getAllIds(treeData).childrenNodes.entries()).length;
  const dynamicPlaceholder = allSelected ? placeholder
    : `${Array.from(selectedChildren.entries()).length} periods`;

  const toggleAll = () => {
    if (allSelected) {
      updateSelections(new Set(),
        new Set());
    } else {
      updateSelections(getAllIds(treeData).parents,
        getAllIds(treeData).childrenNodes);
    }
  };

  const handleBack = () => {
    updateSelections(getAllIds(selectedData).parents,
      getAllIds(selectedData).childrenNodes);
    setFocused(false);
  };

  const mobileContainer = () => {
    return createPortal(<div className='main-white-card absolute top-0 z-30 flex size-full flex-col items-center rounded-none'>
      <div className='flex min-h-14  w-full cursor-pointer items-center
        justify-between px-[0.75rem] py-0.25 shadow-filter-menu'
      >
        <div className='flex items-center gap-0.75' onClick={handleBack}>
          <Svgicon className='size-1' id='UP-arrow-left' />
          <p className='mb-0'>{dynamicPlaceholder}</p>
        </div>
        <span
          className='cursor-pointer text-font-1 text-black-700 hover:text-main-700'
          data-test={'clear-accounts'}
          onClick={toggleAll}
        >
          {`${allSelected ? 'Clear' : 'Select'} all`}
        </span>

      </div>
      <div className='scroll-container size-full self-start'>
        {focused && treeData.map((parentNode) =>
          <div key={parentNode.id}>
            <TreeMultiSelectorOption
              dataSelectionValue={dataSelectionValue}
              halfSelected={isSomeUnderParentSelected(parentNode)}
              isParent
              key={parentNode.id}
              option={parentNode}
              selected={isParentSelected(parentNode)}
              toggleSelect={toggleParentSelection}
            />
            <div className='ml-6'>
              {parentNode.children?.map((childNode) =>
                <TreeMultiSelectorOption
                  dataSelectionValue={dataSelectionValue}
                  isParent={false}
                  key={childNode.id}
                  option={childNode}
                  selected={isChildrenSelected(childNode.id)}
                  toggleSelect={toggleChildrenSelection}
                />,
              )}
            </div>
          </div>,
        )}
      </div>
      <div className='flex w-full justify-center self-center ms:w-fit'><Button
        className=' mx-[0.75rem] my-[0.25rem] w-full ms:w-[8.75rem]'
        data-test='apply-button'
        disabled={!selectedChildren.entries()}
        onClick={applyPeriods}
        size='large'
        squared
        text='Apply'
      /></div>
    </div>, document.body);
  };

  return (
    <>
      {mobile && focused ? mobileContainer() : <div
        className={inputStyle}
        data-test='tree-selector-container'
        onClick={(event) => toggleOpen(!focused, event)}
        ref={componentRef}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <div
          className='flex cursor-pointer flex-col items-start gap-0.125'
          data-test={`${dataSelectionValue}-selected-value`}
          style={{ cursor: 'pointer' }}
        >
          <div
            className='custom-placeholder pointer-events-none text-nowrap !pr-0 pl-[0.625rem] text-font-2'
            data-autofill='disabled'
            data-test={`${dataSelectionValue}-input`}
          >{dynamicPlaceholder}</div>
        </div>
        <div className='flex items-center'>
          {!disabled && !mobile &&
          CustomSuffix(isResettable as boolean, handleReset, allSelected)}
          <span className='size-[1rem] cursor-pointer' onClick={(event) => toggleOpen(!focused, event)}><Svgicon id='arrow-down' /></span>
        </div>
        {focused && <div className={`block ${focused && 'blockActive left-0 top-[1.875rem] !h-auto !max-h-[14.3125rem] !min-w-full'}`}>
          <div className='scroll-container'>
            {focused && treeData.map((parentNode) =>
              <div key={parentNode.id}>
                <TreeMultiSelectorOption
                  dataSelectionValue={dataSelectionValue}
                  halfSelected={isSomeUnderParentSelected(parentNode)}
                  isParent
                  key={parentNode.id}
                  option={parentNode}
                  selected={isParentSelected(parentNode)}
                  toggleSelect={toggleParentSelection}
                />
                <div className='ml-[1.125rem]'>
                  {parentNode.children?.map((childNode) =>
                    <TreeMultiSelectorOption
                      dataSelectionValue={dataSelectionValue}
                      isParent={false}
                      key={childNode.id}
                      option={childNode}
                      selected={isChildrenSelected(childNode.id)}
                      toggleSelect={toggleChildrenSelection}
                    />,
                  )}
                </div>
              </div>,
            )}
          </div>
        </div>}

      </div>}
    </>
  );
};

export default TreeMultiSelector;
