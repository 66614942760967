import style from './Signup.module.scss';
import {
  LoginButton,
  LoginHeading,
  LoginPasswordInput,
} from 'components';
import { Checkbox } from 'components/InteractiveUIControls/Checkbox/Checkbox';
import {
  isPasswordValid,
  PasswordRequirements } from 'components/PasswordRequirements/PasswordRequirements';
import { PasswordStrengthColorBar } from 'components/PasswordStrengthColorBar/PasswordStrengthColorBar';
import { ChangePasswordContext } from 'components/SettingsModule/contexts/ChangePassword.context';
import {
  SignUpActivationContext,
} from 'contexts';
import {
  validatePassword } from 'helpers/helpers';
import {
  useDocumentTitle,
  useToken,
} from 'hooks';
import { useContext,
  useEffect,
  useState } from 'react';
import { useSelector } from 'react-redux';
import {useLocation,
  useNavigate } from 'react-router-dom';
import { passwordRequirements,
  strongPasswordMessage } from 'resources/constants';
import { notificationsService } from 'services';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

export const Signup = () => {
  useDocumentTitle('Sign Up');
  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const { dataToken } = Object.fromEntries(queryParameters.entries());
  const token = useToken();
  const navigate = useNavigate();
  const {
    setIsSignUpInContext,
    setPasswordInContext,
    setTokenInContext,
  } = useContext(SignUpActivationContext);
  useEffect(() => {
    sessionStorage.removeItem('onLoginPage');
  }, []);
  const {
    passwordValidations,
    setPasswordValidations,
  } = useContext(ChangePasswordContext);

  // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;

  const [
    displayPassword,
    setDisplayPassword,
  ] = useState('');
  const [
    confirmDisplayPassword,
    setConfirmDisplayPassword,
  ] = useState('');
  const [
    termsAgreement,
    setTermsAgreement,
  ] = useState(false);

  useEffect(() => {
    if (token) {
      setTokenInContext(token);
    }

    setIsSignUpInContext(true);
  }, [
    dataToken,
    setIsSignUpInContext,
    setTokenInContext,
    token,
  ]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sessionStorage.setItem('onLoginPage', 'true');

    if (displayPassword !== confirmDisplayPassword) {
      notificationsService.error('Password and confirm password does not match');
      return;
    }

    if (!isStrongPassword(displayPassword, passwordRequirements)) {
      notificationsService.error(strongPasswordMessage);
      return;
    }

    setPasswordInContext(displayPassword);
    const parameters = new URLSearchParams();
    parameters.append('token', token ? token : '');
    parameters.append('dataToken', dataToken ? dataToken : '');

    // For now, simply sign up with default method as email instead
    // of allowing users to select their MFA method (Previously, navigate to select-auth).
    navigate(`/auth/select-auth-method?${parameters.toString()}`);
  };

  const handlePasswordChange = (password: string) => {
    setDisplayPassword(password);
    setPasswordValidations(validatePassword(password));
  };

  return <div className={`${style.loginWrapper} flex flex-col gap-1`}>
    <div className='flex flex-col gap-1.5'>
      <LoginHeading className={style.welcomeMessage}>
        Welcome to Investor Portal
      </LoginHeading>
    </div>
    <form className='flex flex-col gap-1' onSubmit={onSubmit}>
      <div className='mb-[-0.5rem]'>
        <div className='mb-[1rem] flex flex-col gap-[0.25rem]'>
          <LoginPasswordInput name='signup-create-password-input' onChange={handlePasswordChange} placeholder='Create Password' styles='!mb-0' value={displayPassword} />
          <PasswordStrengthColorBar
            displayPassword={displayPassword}
            passwordValidations={passwordValidations}
          />
        </div>
        <LoginPasswordInput name='signup-confirm-password-input' onChange={setConfirmDisplayPassword} placeholder='Confirm Password' styles='!mb-[0.5rem]' value={confirmDisplayPassword} />
      </div>
      <PasswordRequirements
        confirmDisplayPassword={confirmDisplayPassword}
        displayPassword={displayPassword}
        isFromSettings={false}
      />
      <div className='flex items-center gap-0.5'>
        <Checkbox
          isGreen
          onChange={() => setTermsAgreement(!termsAgreement)}
          selected={termsAgreement}
        />
        <span className={`${style.termsAndConditions} flex gap-0.25 text-font-3`}>
          I agree with
          <a className='text-label-g-300 underline hover:text-label-g-300' href={'https://www.orcainc.com/terms-of-use'} rel='noreferrer' target='_blank'>terms of use</a>
          and
          <a className='text-label-g-300 underline hover:text-label-g-300' href={'https://www.orcainc.com/privacy-policy'} rel='noreferrer' target='_blank'>privacy policy.</a>
        </span>
      </div>
      <LoginButton disabled={!termsAgreement || !isPasswordValid(passwordValidations, displayPassword, confirmDisplayPassword)} isLoading={isLoading} name='signup-button' text='Next' type='submit' />
    </form>
    <a className={`${style.contactSupport} text-font-2 text-label-g-300 hover:text-label-g-300 hover:underline`} href={'mailto:helpdesk@orcainc.com?cc=fms@orcainc.com'}>
      Contact Support
    </a>
  </div>;
};
