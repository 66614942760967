import { duplicatePasswordMessage,
  passwordRequirements,
  passwordsNotMatchingMessage,
  strongPasswordMessage } from '../../../resources/constants';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

const digitTest = /^\d*$/u;

export const validateVerificationCode = (verificationCode: string) => {
  const errorMsgs: string[] = [];

  if (!digitTest.test(verificationCode)) {
    errorMsgs.push('You can only add digits.');
  }

  if (verificationCode.length > 6) {
    errorMsgs.push('You can only submit a 6-digit code.');
  }

  return {
    canSubmit: verificationCode.length === 6,
    errorMsgs,
    validationPassed: errorMsgs.length === 0,
  };
};

export const validatePhoneNumber = (
  currentPhoneNumber: string,
  newPhoneNumber: string,
) => {
  const errorMsgs: string[] = [];

  if (newPhoneNumber.length === 0) {
    errorMsgs.push('Number must not be empty.');
  }

  if (newPhoneNumber === currentPhoneNumber) {
    errorMsgs.push('Number must be different from the previous number.');
  }

  return {
    canSubmit: errorMsgs.length === 0,
    errorMsgs,
    validationPassed: errorMsgs.length === 0,
  };
};

export type ChangePasswordProperties = 'currentPassword' | 'newPassword' | 'retypeNewPassword';

type PasswordBody = {
  currentPassword: string,
  newPassword: string,
  retypeNewPassword: string,
};

type ValidationResult = {
  canSubmit: boolean,
  errors: Record<ChangePasswordProperties, string[]>,
};

export const getRequirementMessage = (
  requirement: string) => `The ${requirement} is required.`;

export const validatePasswords = ({
  currentPassword,
  newPassword,
  retypeNewPassword,
}: PasswordBody): ValidationResult => {
  const currentPasswordErrors: string[] = [];
  const newPasswordErrors: string[] = [];
  const retypeNewPasswordErrors: string[] = [];

  if (!currentPassword || !newPassword || !retypeNewPassword) {
    if (!currentPassword) {
      currentPasswordErrors.push(getRequirementMessage('current password'));
    }

    if (!newPassword) {
      newPasswordErrors.push(getRequirementMessage('new password'));
    }

    if (!retypeNewPassword) {
      retypeNewPasswordErrors.push(getRequirementMessage('password confirmation'));
    }

    return {
      canSubmit: false,
      errors: {
        currentPassword: currentPasswordErrors,
        newPassword: newPasswordErrors,
        retypeNewPassword: retypeNewPasswordErrors,
      },
    };
  }

  let canSubmit = true;

  if (!isStrongPassword(newPassword, passwordRequirements)) {
    canSubmit = false;
    newPasswordErrors.push(strongPasswordMessage);
  }

  if (newPassword === currentPassword) {
    canSubmit = false;
    currentPasswordErrors.push(duplicatePasswordMessage);
  }

  if (newPassword !== retypeNewPassword) {
    canSubmit = false;
    retypeNewPasswordErrors.push(passwordsNotMatchingMessage);
  }

  return {
    canSubmit,
    errors: {
      currentPassword: currentPasswordErrors,
      newPassword: newPasswordErrors,
      retypeNewPassword: retypeNewPasswordErrors,
    },
  };
};

