import { type PasswordValidations } from 'components/SettingsModule/contexts/ChangePassword.context';

export const barColors = [
  '#C2451E',
  '#C2451E',
  '#C2451E',
  '#FB992F',
  '#00948E',
];
type PasswordStrengthColorBarProps = {
  displayPassword: string,
  passwordValidations: PasswordValidations,
};
export const PasswordStrengthColorBar = ({displayPassword,
  passwordValidations}: PasswordStrengthColorBarProps) => {
  return <div className='flex gap-[0.25rem]'>
    {Array.from({ length: 4 }, (_, index) =>
      <div
        className='flex h-[0.25rem] flex-1 rounded-[0.125rem]'
        key={index}
        style={{
          backgroundColor:
          displayPassword && index < passwordValidations.strengthScore
            ? barColors[passwordValidations.strengthScore]
            : '#e0e0e0',
        }}
      />,
    )}
  </div>;
};
