import { type AxiosInstance } from 'axios';

export const GlobalInterceptor = {
  setup (instance: AxiosInstance) {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          const isFirewallIssue = error.message.includes('Network Error') || error.code === 'ERR_NETWORK';
          if (isFirewallIssue) {
            error.message = 'Network error detected. Please review your firewall settings to confirm that the connection is not being blocked.';
          }
        }

        return Promise.reject(error);
      },
    );
  },
};
