export const strongPasswordMessage = 'New Password isn\'t strong!\n' +
  'Password must be a minimum of 8 characters with at least ' +
  'one of the each following criteria: uppercase, lowercase, number.';

export const passwordRequirements = {
  minLength: 8,
  minLowercase: 1,
  minNumbers: 1,
  minSymbols: 0,
  minUppercase: 1,
};

export const duplicatePasswordMessage = 'New password should not be same as old password';

export const passwordsNotMatchingMessage = 'Passwords are not matching';

// eslint-disable-next-line max-len, require-unicode-regexp, unicorn/no-unsafe-regex
export const VALID_EMAIL_REGEX = /^[\w!#$%&'*+./=?^`{|}~-]+@[\da-z](?:[\da-z-]{0,61}[\da-z])?(?:\.[\da-z](?:[\da-z-]{0,61}[\da-z])?)*$/gi;

export const MAX_ALLOWABLE_MODULE_WIDTH_FOR_REPORT_IMG = 422;
export const MAX_ALLOWABLE_MODULE_WIDTH_FOR_CA_IMG = 422;
export const MAX_ALLOWABLE_WIDTH_FULL_SCREEN = 500;

export const RECAPTCHA = {
  ERRORS: {
    SCORE_TOO_LOW: 'Captcha score too low',
  },
  V2_SITE_KEY: '6LcvKZ0qAAAAAO5FhDhNwI3VMXisNwQcC-RqKYSq',
  V3_SITE_KEY: '6Lc__ZwqAAAAAJTPLHCC5TdS-yi-Mjhvs6uv5Tu9',
};
