import { ButtonArea } from '../ButtonArea/ButtonArea';
import { useBlockDisplay } from '../contexts/BlockDisplayContext.context';
import {
  ChangePhoneNumberContext,
} from '../contexts/ChangePhoneNumber.context';
import { PhoneInput } from './PhoneInput/PhoneInput';
import { VerificationNumberInput } from './VerificationNumberInput/VerificationNumberInput';
import {
  type FC} from 'react';
import {
  useContext,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { notificationsService } from 'services';
import { getItemsFromStorage } from 'types';

type ChangePhoneNumberBlockProps = {
  selectedMethod?: string,
  shouldChangeVerificationMethod: boolean,
};

export const ChangePhoneNumberBlock: FC<ChangePhoneNumberBlockProps> = ({
  shouldChangeVerificationMethod,
  selectedMethod}) => {
  const { loginAs } = getItemsFromStorage([
    'loginAs',
  ], sessionStorage);
  const { setActiveBlock } = useBlockDisplay();
  const phone = useSelector<
  { auth: { phone: string, }, }>(
    (state) => state.auth.phone,
  ) as number;

  const {
    addPhoneNumber,
    addVerificationNumber,
    canInputVerificationCode,
    canSubmitPhoneNumber,
    canSendVerificationCode,
    newPhoneNumber,
    submitChangeNumberRequest,
    submitPhoneNumberErrors,
    submitPhoneNumberLoading,
    submitPhoneNumberSuccess,
    submitVerificationErrors,
    submitVerificationSuccess,
    verificationNumber,
    verificationTimer,
    verifyVerificationNumber,
    submitAddNumberRequest,
    setSubmitVerificationErrors,
    submitVerificationLoading,
  } = useContext(ChangePhoneNumberContext);

  useEffect(() => {
    if (submitVerificationSuccess) {
      notificationsService.success('Phone number changed successfully!');
    }
  }, [
    submitVerificationSuccess,
  ]);

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const result = await verifyVerificationNumber();
    if (result.success) {
      setActiveBlock('edit-fields');
    }
  };

  const renderChild = () => <div className='flex h-full flex-col justify-between gap-1 dt:h-auto'>
    <div className='flex flex-col gap-1'>
      <div className='flex flex-col gap-[0.25rem]'>
        <p className='m-0 text-title-3 font-medium dt:text-font-1'>{`${phone ? 'Change your phone' : 'Add your phone'}`}</p>
        <p className='m-0 text-font-2 font-normal text-black-700'>Note that if you {`${phone ? 'change' : 'add'}`} your phone, we will need you verify it{`${phone ? '' : ' via Whatsapp'}`}.</p>
      </div>
      <PhoneInput
        canSubmitPhoneNumber={canSubmitPhoneNumber}
        disabled={Boolean(loginAs) || loginAs === 'true'}
        errorMessages={submitPhoneNumberErrors}
        loading={submitPhoneNumberLoading}
        name={'New phone'}
        onChange={addPhoneNumber}
        selectedMethod={selectedMethod}
        setSubmitVerificationErrors={setSubmitVerificationErrors}
        shouldChangeVerificationMethod={shouldChangeVerificationMethod}
        submitAddNumberRequest={submitAddNumberRequest}
        submitPhoneNumber={submitChangeNumberRequest}
        value={newPhoneNumber}
        verificationTimer={verificationTimer}
      />
      {
        submitPhoneNumberSuccess && canInputVerificationCode && <VerificationNumberInput
          errorMessages={submitVerificationErrors}
          name={'Verification Code'}
          onChange={addVerificationNumber}
          value={verificationNumber}
        />
      }
    </div>
    <ButtonArea
      buttonText='Save'
      disabled={!canSendVerificationCode}
      isLoading={submitVerificationLoading}
      onCancel={() => setActiveBlock('edit-fields')}
      onSave={(event) => handleSubmit(event as React.MouseEvent<HTMLElement>)}
    />
  </div>;

  return renderChild();
};
