/* eslint-disable no-undef */
/* eslint-disable canonical/filename-match-exported */
import axios from 'axios';
import environment from 'config/environment';

const localhostEnvironment = environment.apiUrl;

export const contentTypes = {
  formData: 'multipart/form-data',
  json: 'application/json',
};

class Api {
  constructor (baseUrl = '/') {
    this.baseUrl = localhostEnvironment + baseUrl;
    this.config = {};
  }

  get (url) {
    return axios(this.buildConfig(url));
  }

  post (url, data, options = {}, multipart = false) {
    return axios({method: 'post',
      ...this.buildConfig(
        url,
        data,
        multipart ? contentTypes.formData : contentTypes.json,
      ),
      ...options});
  }

  buildConfig (url, data, contentType) {
    const token = sessionStorage.getItem('access_token');

    this.config.headers = {
      'Content-Type': contentType || 'application/json',
    };
    this.config.url = url ? this.baseUrl + url : this.baseUrl;
    this.config.withCredentials = true;
    if (token) {
      this.config.headers.Authorization = `Bearer ${token}`;
    }

    if (data) {
      this.config.data = data;
    }

    return this.config;
  }
}

export default Api;
