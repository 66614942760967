import { type FC } from 'react';

type EditableFieldProps = {
  buttonName: string,
  dataTest: string,
  onEdit: () => void,
  title: string,
  value?: string,
};

export const EditableField: FC<EditableFieldProps> = ({
  onEdit,
  title,
  value,
  buttonName,
  dataTest,
}) => {
  const withValue = () => value ? <div className='flex flex-col items-start gap-[0.25rem]'>
    <span className='text-font-2 text-rgba-black-700'>
      {title}
    </span>
    <span className='text-font-1 text-black-999'>
      {value}
    </span>
  </div> : <span className='text-font-2 text-rgba-black-700'>
    {title}
  </span>;

  return (
    <div className='flex items-center justify-between self-stretch'>
      {withValue()}
      <button
        className='flex h-8 items-center justify-center gap-1 rounded-[2rem] border border-main-700 px-[1.25rem]'
        data-test={dataTest}
        onClick={onEdit}
        type='button'
      >
        <span className='text-sm font-normal leading-tight text-main-700'>
          {buttonName}
        </span>
      </button>
    </div>
  );
};
