import { getItemsFromStorage } from '../types';
import axios, { type AxiosInstance,
  type AxiosRequestConfig } from 'axios';
import environment from 'config/environment';
import { GlobalInterceptor } from 'hooks/globalInterceptor';

const localhostEnvironment = environment.apiUrl;

const contentTypes = {
  formData: 'multipart/form-data' as FormData,
  json: 'application/json' as Json,
};

type FormData = 'multipart/form-data';

type Json = 'application/json';

type Pdf = 'application/pdf';

export type ContentTypes = FormData | Json | Pdf;

class Api {
  private baseUrl: string;

  private instance: AxiosInstance;

  public constructor (baseUrl = '/') {
    this.baseUrl = localhostEnvironment + baseUrl;

    this.instance = axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    });

    GlobalInterceptor.setup(this.instance);
  }

  public get<T> (url: string, responseType: AxiosRequestConfig['responseType'] = 'json', contentType?: ContentTypes) {
    const config = this.buildConfig(url,
      contentType || contentTypes.json,
      undefined,
      responseType);

    return this.instance.get<T>(config.url as string, config);
  }

  public post<D, T> (
    url: string,
    data: D,
    options: Partial<AxiosRequestConfig>,
    params?: unknown,
    multipart = false,
  ) {
    const contentType = multipart ? contentTypes.formData : contentTypes.json;

    const config = this.buildConfig(
      url,
      contentType,
      data,
    );

    return this.instance.post<T>(
      config.url as string,
      config.data,
      { ...config,
        params,
        ...options },
    );
  }

  private buildConfig<D> (url: string, contentType: ContentTypes, data?: D, responseType?: AxiosRequestConfig['responseType']) {
    const config: AxiosRequestConfig<D> = {};

    const token = getItemsFromStorage<{ access_token: string, }>([
      'access_token',
    ], sessionStorage).access_token;
    config.headers = {
      'Content-Type': contentType || 'application/json',
    };
    config.url = url ? this.baseUrl + url : this.baseUrl;
    config.withCredentials = true;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (responseType) {
      config.responseType = responseType;
    }

    if (data) {
      config.data = data;
    }

    return config;
  }
}

export {Api};
