import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { Login } from './Login/Login';
import { Migration } from './Migration/Migration';
import { ResendVerification } from './ResendVerification/ResendVerification';
import { ResetPasswordConfirm } from './ResetPasswordConfirm/ResetPasswordConfirm';
import { SelectAuthentication } from './SelectAuthentication';
import { Signup } from './Signup/Signup';
import { Verification } from './Verification/Verification';
import { type SubRoute } from 'components';
import { ChangePasswordContextProvider } from 'components/SettingsModule/contexts/ChangePassword.context';

const ROUTE_ARRAY: SubRoute[] = [
  {
    element: Login,
    subRouteString: 'login',
  },
  {
    element: () =>
      <ChangePasswordContextProvider>
        <Signup />
      </ChangePasswordContextProvider>,
    subRouteString: 'signup',
  },
  {
    element: ResendVerification,
    subRouteString: 'verification/method',
  },
  {
    element: Verification,
    subRouteString: 'verification',
  },
  {
    element: SelectAuthentication,
    subRouteString: 'select-auth-method',
  },
  {
    element: ForgotPassword,
    subRouteString: 'forgot-password',
  },
  {
    element: Migration,
    subRouteString: 'migration',
  },
  {
    element: () =>
      <ChangePasswordContextProvider>
        <ResetPasswordConfirm />
      </ChangePasswordContextProvider>,
    subRouteString: 'reset-password-confirm',
  },
];

export { ROUTE_ARRAY };
