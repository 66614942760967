import { ButtonArea } from '../ButtonArea/ButtonArea';
import { useBlockDisplay } from '../contexts/BlockDisplayContext.context';
import { ChangePasswordContext} from '../contexts/ChangePassword.context';
import { LoginPasswordInput } from 'components/LoginPasswordInput/LoginPasswordInput';
import {
  isPasswordValid,
  PasswordRequirements } from 'components/PasswordRequirements/PasswordRequirements';
import { PasswordStrengthColorBar } from 'components/PasswordStrengthColorBar/PasswordStrengthColorBar';
import {
  validatePassword } from 'helpers/helpers';
import { useContext,
  useEffect } from 'react';
import { notificationsService } from 'services';

export const ChangePasswordBlock = () => {
  const { setActiveBlock } = useBlockDisplay();
  const {
    addCurrentPassword,
    addNewPassword,
    addRetypeNewPassword,
    changePasswordErrors,
    changePasswordLoading,
    changePasswordSuccess,
    currentPassword,
    newPassword,
    retypeNewPassword,
    submitPasswordBody,
    passwordValidations,
    setPasswordValidations,
  } = useContext(ChangePasswordContext);

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    await submitPasswordBody();
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      notificationsService.success('Password changed successfully!');
      setActiveBlock('edit-fields');
    }
  }, [
    changePasswordSuccess,
    setActiveBlock,
  ]);

  return <div className='flex h-full flex-col justify-between gap-1 dt:h-auto'>
    <div className='flex flex-col gap-[1rem]'>
      <p className='m-0 text-title-3 dt:text-font-1'>Change your password</p>
      <div className='flex flex-col gap-[0.75rem]'>
        <div>
          <span className='text-font-3'>Current password</span>
          <LoginPasswordInput autoCompleteField='new-password' errorMessages={changePasswordErrors.currentPassword} onChange={(input: string) => addCurrentPassword(input)} placeholder='Current password' styles='!m-0' value={currentPassword} />
        </div>
        <div>
          <span className='text-font-3'>New password</span>
          <LoginPasswordInput
            autoCompleteField='new-password'
            errorMessages={changePasswordErrors.newPassword}
            name='login-password-input'
            onChange={(input: string) => {
              addNewPassword(input);
              setPasswordValidations(validatePassword(input));
            }}
            placeholder='New password'
            styles='!mb-[0.25rem]'
            value={newPassword}
          />
          <PasswordStrengthColorBar
            displayPassword={newPassword}
            passwordValidations={passwordValidations}
          />
        </div>
        <div>
          <span className='text-font-3'>Confirm new password</span>
          <LoginPasswordInput autoCompleteField='new-password' errorMessages={changePasswordErrors.retypeNewPassword} onChange={(input: string) => addRetypeNewPassword(input)} placeholder='Confirm new password' styles='!m-0' value={retypeNewPassword} />
        </div>
        <PasswordRequirements
          confirmDisplayPassword={retypeNewPassword}
          displayPassword={newPassword}
          isFromSettings
        />
      </div>
    </div>
    <ButtonArea buttonText='Save' disabled={!currentPassword.length || !newPassword.length || !retypeNewPassword.length || !isPasswordValid(passwordValidations, newPassword, retypeNewPassword)} isLoading={changePasswordLoading} onCancel={() => setActiveBlock('edit-fields')} onSave={(event) => handleSubmit(event as React.MouseEvent<HTMLElement>)} />
  </div>;
};

