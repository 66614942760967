import './DocumentCategoryName.scss';
import ClosedChevron from 'assets/icons/documents/closed-chevron.svg?react';
import OpenedChevron from 'assets/icons/documents/opened-chevron.svg?react';
import classNames from 'classnames';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Tooltip } from 'components/InteractiveUIControls/Tooltip/Tooltip';
import { ModalActivationContext } from 'contexts';
import { type FC,
  useContext } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'services/mobile';
import { type DocumentsStoreState } from 'store/slices';

type DocumentCategoryNameProps = {
  categoryName: string,
  className?: string,
  isNewDocumentsCategory: boolean,
  isOpen: boolean,
  totalNumberOfDocuments: number,
};

export const DocumentCategoryName: FC<DocumentCategoryNameProps> = ({
  categoryName,
  isOpen,
  totalNumberOfDocuments,
  isNewDocumentsCategory,
  className,
}) => {
  const { toggleDownloadsModal } = useContext(ModalActivationContext);
  const {
    displayType,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const onClickDownload = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    toggleDownloadsModal(true, categoryName);
  };

  return (
    <>
      {
        isOpen ?
          <div className='open-name-container'>
            <div className='open-name-left'>
              <OpenedChevron />
              <div className='flex flex-col gap-[0.25rem]'>
                <h3 className='category-name'>
                  {categoryName}
                </h3>
                {!isMobile() && isOpen && displayType.id === 'period' && <Button
                  className={classNames(className, 'fileViewButton !hidden')}
                  data-test='download-file'
                  icon='left'
                  iconid='download-cloud'
                  onClick={(event) => onClickDownload(event)}
                  size='medium'
                >
                  Download
                </Button>}
              </div>
            </div>
          </div> :
          <div className='closed-name-container'>
            <div className='closed-name-left'>
              <ClosedChevron />
              <h3 className='category-name'>
                {categoryName}
              </h3>
            </div>
            {!isNewDocumentsCategory && <div className='closed-name-right'>
              <div className='tooltip-wrapper black w-fit'>
                <Tooltip>{`${totalNumberOfDocuments} document${totalNumberOfDocuments === 1 ? '' : 's'}`}</Tooltip>
                <div className='closed-num-documents-container'>
                  <p className='total-number-of-documents'>
                    {totalNumberOfDocuments}
                  </p>
                </div>
              </div>
            </div>}
          </div>
      }
    </>
  );
};
