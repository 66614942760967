import { type SelectDisplayOption } from '../../../hooks';
import { DropdownOption } from '../DropdownOption';
import style from './DropdownOptionList.module.scss';
import { Input } from 'antd';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import {
  type ChangeEvent,
  type FC,
  useRef,
} from 'react';

type DropdownOptionsProps = {
  activateOthers: boolean,
  allowOthersOption: boolean,
  dataSelectionValue: string,
  focused: boolean,
  inputValue: string,
  onClickOption: (isOthersOption: boolean) => (option: SelectDisplayOption) =>
  (event: React.MouseEvent<HTMLDivElement>) => void,
  onInputButtonClick: () => void,
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
  optionContainerStyle?: string,
  optionTextStyle?: string,
  options: SelectDisplayOption[],
  optionsClassName?: string,
  selectedOptionContainerStyle?: string,
  withTickIcon?: boolean,
};

const OTHERS_OPTION = {
  display: 'Others',
  id: 'Others',
};

const onInputClick = (event: React.MouseEvent<HTMLInputElement>): void => {
  event.stopPropagation();
  event.preventDefault();
};

export const DropdownOptionList: FC<DropdownOptionsProps> = ({
  activateOthers,
  allowOthersOption,
  dataSelectionValue,
  inputValue,
  options,
  focused,
  onClickOption,
  onInputButtonClick,
  onInputChange,
  optionTextStyle,
  optionsClassName,
  withTickIcon,
  optionContainerStyle,
  selectedOptionContainerStyle,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      {focused ?
        <div
          className={`${style.block} ${optionsClassName}`}
          ref={ref}
        >
          <div className={style.optionContainer}>
            {
              !activateOthers &&
              options.map((option) =>
                allowOthersOption && option.id === OTHERS_OPTION.id ?
                  <DropdownOption
                    dataSelectionValue={dataSelectionValue}
                    key={option.id}
                    onClickOption={onClickOption(true)}
                    option={option}
                    optionContainerStyle={optionContainerStyle}
                    optionTextStyle={optionTextStyle}
                    selectedOptionContainerStyle={selectedOptionContainerStyle}
                    withTickIcon={withTickIcon || false}
                  /> :
                  <DropdownOption
                    dataSelectionValue={dataSelectionValue}
                    key={option.id}
                    onClickOption={onClickOption(false)}
                    option={option}
                    optionContainerStyle={optionContainerStyle}
                    optionTextStyle={optionTextStyle}
                    selectedOptionContainerStyle={selectedOptionContainerStyle}
                    withTickIcon={withTickIcon || false}
                  />)
            }
            {
              allowOthersOption && activateOthers &&

              <div
                className={style.inputContainer}
              >
                <Input
                  className={style.inputBox}
                  onChange={onInputChange}
                  onClick={onInputClick}
                  placeholder={OTHERS_OPTION.display}
                  suffix={
                    <Button
                      icon='left'
                      iconid='check-2f'
                      onClick={() => onInputButtonClick()}
                      size='large'
                      type='outline-main'
                    >
                      Submit option
                    </Button>
                  }
                  value={inputValue}
                />
              </div>

            }
          </div>
        </div> : null}
    </>);
};
