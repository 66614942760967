import './PhoneInput.scss';
import classNames from 'classnames';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { LoginPhoneInput } from 'components/LoginPhoneInput/LoginPhoneInput';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type FC } from 'react';
import { isMobile } from 'services/mobile';

type PhoneInputProps = {
  canSubmitPhoneNumber: boolean,
  disabled?: boolean,
  errorMessages: string[],
  loading: boolean,
  name: string,
  onChange: (input: string) => void,
  selectedMethod?: string,
  setSubmitVerificationErrors?: React.Dispatch<React.SetStateAction<string[]>>,
  shouldChangeVerificationMethod: boolean,
  submitAddNumberRequest?: (selectedMethod: string) => Promise<void>,
  submitPhoneNumber: () => Promise<void>,
  value: string,
  verificationTimer: number,
};

export const PhoneInput: FC<PhoneInputProps> = ({
  canSubmitPhoneNumber,
  disabled,
  name,
  loading,
  onChange,
  value,
  errorMessages,
  submitPhoneNumber,
  verificationTimer,
  shouldChangeVerificationMethod,
  submitAddNumberRequest,
  selectedMethod,
  setSubmitVerificationErrors,
}) => {
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (setSubmitVerificationErrors) {
      setSubmitVerificationErrors([]);
    }

    if (shouldChangeVerificationMethod && submitAddNumberRequest && selectedMethod) {
      await submitAddNumberRequest(selectedMethod);
    } else {
      await submitPhoneNumber();
    }
  };

  return <div className='flex w-full flex-col'>
    <div>
      <span className='font-inter text-[0.75rem] font-normal text-black-999'>{name}</span>
      <div className='relative mb-[0.5rem] flex items-center justify-between rounded border border-[#CCC] pr-[0.25rem]'>
        <LoginPhoneInput
          className='font-inter text-[0.875rem]'
          onChange={onChange}
          value={value}
        />
        {!isMobile() && <button
          className={classNames('flex px-[0.75rem] py-[0.125rem] justify-center align-center gap-[0.25rem] border rounded-[1rem]', disabled || verificationTimer || !canSubmitPhoneNumber ? 'border-[#0F6CBD] bg-[#0F6CBD] opacity-50' : 'border-[#0F6CBD] bg-[#0F6CBD]')}
          data-test='send-verification-code-button'
          disabled={disabled || !canSubmitPhoneNumber || Boolean(verificationTimer)}
          onClick={handleSubmit}
          type='button'
        >
          {
            loading ?
              <div className='spinner-svg'><Svgicon color='white' id='other-11f' /> </div> :
              <span className='font-inter text-normal text-[0.75rem] text-[#FFF]'>
                {
                  verificationTimer ?
                    `Resend on ${verificationTimer} seconds` :
                    'Send Verification Code'
                }
              </span>
          }
        </button>}
      </div>
      {isMobile() && <Button
        className='w-full !px-6 !py-[0.31rem] !text-font-2 !font-normal'
        disabled={disabled || !canSubmitPhoneNumber || Boolean(verificationTimer)}
        onClick={(event) => handleSubmit(event as React.MouseEvent<HTMLButtonElement>)}
        size='large'
        squared
        tooltipWrapperClass='w-full'
        type='main'
      >
        {
          loading ?
            <div className='spinner-svg'><Svgicon color='white' id='other-11f' /> </div> :
            <span>
              {
                verificationTimer ?
                  `Resend on ${verificationTimer} seconds` :
                  'Send Verification Code'
              }
            </span>
        }
      </Button>}
      {
        errorMessages.length > 0 &&
        <ul className='font-inter text-red list-inside list-disc text-[0.5rem]'>
          {errorMessages.map((error) =>
            <li key={error}>{error}</li>,
          )}
        </ul>
      }
    </div>
  </div>;
};
