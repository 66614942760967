import { CapitalAccounts } from './CapitalAccounts';
import { ChangePass } from './ChangePass';
import { Documents } from './Documents';
import { type SubRoute } from 'components';

const BASE_PATH = '/firm';

const ROUTE_ARRAY: SubRoute[] = [
  {
    element: Documents,
    subRouteString: 'documents',
  },
  {
    element: CapitalAccounts,
    parameter: ':fundId/:accountId',
    subRouteString: 'capital-accounts',
  },
  {
    element: CapitalAccounts,
    subRouteString: 'capital-accounts',
  },
  {
    element: ChangePass,
    subRouteString: 'change-password',
  },
];

export {
  BASE_PATH,
  ROUTE_ARRAY,
};
