import 'react-phone-input-2/lib/style.css';
import style from './LoginPhoneInput.module.scss';
import axios from 'axios';
import { useEffect,
  useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { notificationsService } from 'services';

type Props = {
  // for e2e tests, so element can be find easily
  className?: string,
  name?: string,
  onChange?: (string_: string) => void,
  value?: string,
};

export const LoginPhoneInput = ({
  className = '',
  value = '',
  onChange = () => {},
  name = '',
}: Props) => {
  const [
    country,
    setCountry,
  ] = useState('us');

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code.toLowerCase();
        setCountry(countryCode);
      } catch {
        notificationsService.error('Cannot fetch country code');
      }
    };

    fetchCountry();
  }, []);

  return <div>
    <PhoneInput
      buttonClass={style.button}
      containerClass={[
        style.loginPhoneInput,
        className,
      ].join('')}
      country={country}
      data-test={name}
      dropdownClass={style.dropdown}
      enableAreaCodes
      enableLongNumbers
      inputClass={style.input}
      inputProps={{'data-test': name}}
      onChange={(text) => onChange(text)}
      placeholder='Your phone number'
      value={value}
    />
  </div>;
};
