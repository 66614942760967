import {
  toggleCategory,
} from '../../../store/slices';
import {
  type DocumentCategory as DocumentCategoryType,
} from '../../../types';
import style from './DocumentCategory.module.scss';
import { DocumentCategoryName } from './DocumentCategoryName';
import { DocumentGroup } from './DocumentGroup';
import {
  type FC,
} from 'react';
import {
  useDispatch,
} from 'react-redux';

export const DocumentCategory: FC<DocumentCategoryType> = ({
  categoryId: topLevelCategoryId,
  categoryName,
  documentTypeGroups,
  isNewDocumentsCategory,
  isOpen,
  totalNumberOfDocuments,
}) => {
  const dispatch = useDispatch();

  const toggleCategoryOnClick = () => {
    dispatch(toggleCategory({
      isNewDocumentsCategory,
      isOpen: !isOpen,
      topLevelCategoryId,
    }));
  };

  const toggleCategoryOnKeyDown = (
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!event || event.key !== 'Tab') {
      return;
    }

    dispatch(toggleCategory({
      isNewDocumentsCategory,
      isOpen: !isOpen,
      topLevelCategoryId,
    }));
  };

  return (
    <div
      aria-expanded={isOpen}
      className={style.categoryContainer}
      data-test={`document-category-${categoryName}`}
      onClick={toggleCategoryOnClick}
      onKeyDown={toggleCategoryOnKeyDown}
      role='button'
      tabIndex={0}
    >
      {
        isOpen ?
          <>
            <DocumentCategoryName
              categoryName={categoryName}
              className={style.categoryName}
              isNewDocumentsCategory={isNewDocumentsCategory}
              isOpen={isOpen}
              totalNumberOfDocuments={totalNumberOfDocuments}
            />
            <div className={style.documentGroupsContainer}>
              {
                documentTypeGroups.map((group) => <DocumentGroup
                  isNewDocumentsCategory={isNewDocumentsCategory}
                  // eslint-disable-next-line max-len
                  key={`${topLevelCategoryId}${group.categoryId}${group.documentGroupId}${isNewDocumentsCategory}`}
                  topLevelCategoryId={topLevelCategoryId}
                  {...group}
                />)
              }
            </div>
          </> :
          <div className={style.closedCategoryContainer}>
            <DocumentCategoryName
              categoryName={categoryName}
              isNewDocumentsCategory={isNewDocumentsCategory}
              isOpen={isOpen}
              totalNumberOfDocuments={totalNumberOfDocuments}
            />
          </div>
      }
    </div>
  );
};
