import { Api } from './Api';
import { Alert,
  unauthorized } from './utils';
import { isAxiosError } from 'axios';

const dataRoomApiRequester = new Api('/api/v1/data-room');

const getDataRoomToken = async ({
  firmId,
  folderName,
}: {
  firmId: string,
  folderName: string,
}) => {
  const url = `/token?firmId=${firmId}&folderName=${folderName}`;

  try {
    const {data} = await dataRoomApiRequester.get<{token: string,}>(
      url,
    );

    return data.token;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve report');
    }

    throw new Error('Could not retrieve report.');
  }
};

export type GetDataRoomFoldersResponse = {
  dataRoomFolders: string[],
};

type FirmAndFundsForDataRoom = {
  firmId: string,
  fundIds: string[],
};
const getDataRoomFolders = async (firmAndFunds: FirmAndFundsForDataRoom) => {
  try {
    const {data} = await dataRoomApiRequester
      .post<FirmAndFundsForDataRoom, GetDataRoomFoldersResponse>('/all', firmAndFunds, {});

    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve reports');
    }

    throw new Error('Could not retrieve reports.');
  }
};

export const dataRoomApi = {
  getDataRoomFolders,
  getDataRoomToken,
};

