/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line import/no-unassigned-import
import './antd.scss';
import { Spinner } from '../Spinner';
import style from './support.module.scss';
import { SupportForm } from './SupportForm';
import { SupportSuccessNotification } from './SupportSuccessNotification';
import classNames from 'classnames';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { GlobalElementBusContext,
  ModalActivationContext } from 'contexts';
import { useContext,
  useEffect,
  useRef,
  useState} from 'react';
import { isMobile } from 'services/mobile';

export const Support = () => {
  const containerRef = useRef(null);
  const {
    addRef,
  } = useContext(GlobalElementBusContext);
  const mobile = isMobile();
  const [
    supportLoading,
    setSupportLoading,
  ] = useState(false);

  const [
    supportSuccess,
    setSupportSuccess,
  ] = useState(false);

  const { toggleSupportModal } = useContext(ModalActivationContext);

  useEffect(() => {
    if (containerRef.current) {
      addRef(containerRef);
    }
  }, [
    addRef,
    containerRef,
  ]);

  const produceChildrenComponents = (
    renderSpinner: boolean, renderSuccess: boolean,
  ) => {
    if (renderSpinner) {
      return <Spinner />;
    }

    if (renderSuccess) {
      return <SupportSuccessNotification
        onBackToDashboard={() => toggleSupportModal(false)}
      />;
    }

    return <SupportForm
      setSupportLoading={setSupportLoading}
      setSupportModal={toggleSupportModal}
      setSupportSuccess={setSupportSuccess}
    />;
  };

  return (
    <div className={classNames(style.container, 'z-[1000]')} ref={containerRef}>
      <div className='relative flex size-full items-center justify-center'>
        <div className='main-white-card relative flex size-full items-center rounded-none dt:h-auto dt:min-h-[35em] dt:w-[30em] dt:rounded-main'>
          <span
            className='absolute right-1 top-1 z-[1010] size-1.75 cursor-pointer dt:right-[0.375rem] dt:top-[0.375rem] dt:size-1.5'
            data-test='download-files-modal-close-button'
            onClick={() => toggleSupportModal(false)}
          >
            { mobile ? <Svgicon id='menu-close' /> : <Svgicon className='rounded-[50%] bg-main-700 text-white-999' id='close-1f' />}
          </span>
          {produceChildrenComponents(supportLoading, supportSuccess)}
        </div>
      </div>
    </div>
  );
};
